import color from "color";
import twcolors from 'tailwindcss/colors';

export const colors = twcolors;

export function apply(theme: string, palette: { [key: string]: string }) {
    const root = document.documentElement;
    if (typeof palette === "object") {
        for (let shade in palette) {
            const [r, g, b] = color(palette[shade]).rgb().array();
            root.style.setProperty(`--color-${theme}-${shade}-r`, String(r));
            root.style.setProperty(`--color-${theme}-${shade}-g`, String(g));
            root.style.setProperty(`--color-${theme}-${shade}-b`, String(b));
        }
    }
}

export default { apply, colors };
