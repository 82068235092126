/* eslint-disable @typescript-eslint/no-redeclare */

// Board actions
export const BOARD_LOADED = "BOARD_LOADED";
export type BOARD_LOADED = typeof BOARD_LOADED;

export const LOAD_BOARD = "LOAD_BOARD";
export type LOAD_BOARD = typeof LOAD_BOARD;

export const BOARD_PURGED = "BOARD_PURGED";
export type BOARD_PURGED = typeof BOARD_PURGED;

export const BOARD_CONNECTED = "BOARD_CONNECTED";
export type BOARD_CONNECTED = typeof BOARD_CONNECTED;

export const BOARD_FILTER_UPDATED = "BOARD_FILTER_UPDATED";
export type BOARD_FILTER_UPDATED = typeof BOARD_FILTER_UPDATED;

export const LOAD_BOARDS = "LOAD_BOARDS";
export type LOAD_BOARDS = typeof LOAD_BOARDS;

export const BOARDS_LOADED = "BOARDS_LOADED";
export type BOARDS_LOADED = typeof BOARDS_LOADED;

export const BOARD_DELETED = "BOARD_DELETED";
export type BOARD_DELETED = typeof BOARD_DELETED;

export const CREATE_BOARD = "CREATE_BOARD";
export type CREATE_BOARD = typeof CREATE_BOARD;

export const BOARD_CREATED = "BOARD_CREATED";
export type BOARD_CREATED = typeof BOARD_CREATED;

export const DELETE_BOARD = "DELETE_BOARD";
export type DELETE_BOARD = typeof DELETE_BOARD;

export const UPDATE_BOARD = "UPDATE_BOARD";
export type UPDATE_BOARD = typeof UPDATE_BOARD;

export const ARCHIVE_BOARD = "ARCHIVE_BOARD";
export type ARCHIVE_BOARD = typeof ARCHIVE_BOARD;

export const UNARCHIVE_BOARD = "UNARCHIVE_BOARD";
export type UNARCHIVE_BOARD = typeof UNARCHIVE_BOARD;

export const BOARD_ARCHIVED = "BOARD_ARCHIVED";
export type BOARD_ARCHIVED = typeof BOARD_ARCHIVED;

export const BOARD_UNARCHIVED = "BOARD_UNARCHIVED";
export type BOARD_UNARCHIVED = typeof BOARD_UNARCHIVED;

export const BOARD_UPDATED = "BOARD_UPDATED";
export type BOARD_UPDATED = typeof BOARD_UPDATED;

// Collection actions
export const GET_COLLECTION = "GET_COLLECTION";
export type GET_COLLECTION = typeof GET_COLLECTION;

export const CREATE_COLLECTION = "CREATE_COLLECTION";
export type CREATE_COLLECTION = typeof CREATE_COLLECTION;

export const COLLECTION_LOADED = "COLLECTION_LOADED";
export type COLLECTION_LOADED = typeof COLLECTION_LOADED;

export const STORE_COLLECTION = "STORE_COLLECTION";
export type STORE_COLLECTION = typeof STORE_COLLECTION;

export const STORE_COLLECTIONS = "STORE_COLLECTIONS";
export type STORE_COLLECTIONS = typeof STORE_COLLECTIONS;

export const MOVE_COLLECTION = "MOVE_COLLECTION";
export type MOVE_COLLECTION = typeof MOVE_COLLECTION;

export const COLLECTION_MOVED = "COLLECTION_MOVED";
export type COLLECTION_MOVED = typeof COLLECTION_MOVED;

export const COLLECTIONS_LOADED = "COLLECTIONS_LOADED";
export type COLLECTIONS_LOADED = typeof COLLECTIONS_LOADED;

export const DELETE_COLLECTION = "DELETE_COLLECTION";
export type DELETE_COLLECTION = typeof DELETE_COLLECTION;

export const UPDATE_COLLECTION = "UPDATE_COLLECTION";
export type UPDATE_COLLECTION = typeof UPDATE_COLLECTION;

export const LOAD_COLLECTIONS = "LOAD_COLLECTIONS";
export type LOAD_COLLECTIONS = typeof LOAD_COLLECTIONS;

export const BOARD_COLLECTIONS_LOADED = "BOARD_COLLECTIONS_LOADED";
export type BOARD_COLLECTIONS_LOADED = typeof BOARD_COLLECTIONS_LOADED;

export const FETCH_COLLECTIONS = "FETCH_COLLECTIONS";
export type FETCH_COLLECTIONS = typeof FETCH_COLLECTIONS;

export const ARCHIVE_COLLECTION = "ARCHIVE_COLLECTION";
export type ARCHIVE_COLLECTION = typeof ARCHIVE_COLLECTION;

export const UNARCHIVE_COLLECTION = "UNARCHIVE_COLLECTION";
export type UNARCHIVE_COLLECTION = typeof UNARCHIVE_COLLECTION;

export const COLLECTIONS_REORDERED = "COLLECTIONS_REORDERED";
export type COLLECTIONS_REORDERED = typeof COLLECTIONS_REORDERED;

export const COLLECTION_CREATED = "COLLECTION_CREATED";
export type COLLECTION_CREATED = typeof COLLECTION_CREATED;

export const COLLECTION_UPDATED = "COLLECTION_UPDATED";
export type COLLECTION_UPDATED = typeof COLLECTION_UPDATED;

export const COLLECTIONS_UPDATED = "COLLECTIONS_UPDATED";
export type COLLECTIONS_UPDATED = typeof COLLECTIONS_UPDATED;

export const COLLECTION_DELETED = "COLLECTION_DELETED";
export type COLLECTION_DELETED = typeof COLLECTION_DELETED;

export const COLLECTION_ARCHIVED = "COLLECTION_ARCHIVED";
export type COLLECTION_ARCHIVED = typeof COLLECTION_ARCHIVED;

export const COLLECTION_UNARCHIVED = "COLLECTION_UNARCHIVED";
export type COLLECTION_UNARCHIVED = typeof COLLECTION_UNARCHIVED;

// Record actions
export const GET_RECORD = "GET_RECORD";
export type GET_RECORD = typeof GET_RECORD;

export const RECORD_LOADED = "RECORD_LOADED";
export type RECORD_LOADED = typeof RECORD_LOADED;

export const RECORDS_LOADED = "RECORDS_LOADED";
export type RECORDS_LOADED = typeof RECORDS_LOADED;

export const STORE_RECORD = "STORE_RECORD";
export type STORE_RECORD = typeof STORE_RECORD;

export const STORE_RECORDS = "STORE_RECORDS";
export type STORE_RECORDS = typeof STORE_RECORDS;

export const CREATE_RECORD = "CREATE_RECORD";
export type CREATE_RECORD = typeof CREATE_RECORD;

export const DELETE_RECORD = "DELETE_RECORD";
export type DELETE_RECORD = typeof DELETE_RECORD;

export const MOVE_RECORD = "MOVE_RECORD";
export type MOVE_RECORD = typeof MOVE_RECORD;

export const RECORD_MOVED = "RECORD_MOVED";
export type RECORD_MOVED = typeof RECORD_MOVED;

export const UPDATE_RECORD = "UPDATE_RECORD";
export type UPDATE_RECORD = typeof UPDATE_RECORD;

export const FETCH_RECORDS = "FETCH_RECORDS";
export type FETCH_RECORDS = typeof FETCH_RECORDS;

export const ARCHIVE_RECORD = "ARCHIVE_RECORD";
export type ARCHIVE_RECORD = typeof ARCHIVE_RECORD;

export const LOAD_RECORD = "LOAD_RECORD";
export type LOAD_RECORD = typeof LOAD_RECORD;

export const LOAD_RECORDS = "LOAD_RECORDS";
export type LOAD_RECORDS = typeof LOAD_RECORDS;

export const LOAD_ARCHIVED_RECORDS = "LOAD_ARCHIVED_RECORDS";
export type LOAD_ARCHIVED_RECORDS = typeof LOAD_ARCHIVED_RECORDS;

export const BOARD_RECORDS_LOADED = "BOARD_RECORDS_LOADED";
export type BOARD_RECORDS_LOADED = "BOARD_RECORDS_LOADED";

export const RECORD_CREATED = "RECORD_CREATED";
export type RECORD_CREATED = typeof RECORD_CREATED;

export const RECORD_LABELED = "RECORD_LABELED";
export type RECORD_LABELED = typeof RECORD_LABELED;

export const RECORD_UNLABELED = "RECORD_UNLABELED";
export type RECORD_UNLABELED = typeof RECORD_UNLABELED;

export const RECORD_ARCHIVED = "RECORD_ARCHIVED";
export type RECORD_ARCHIVED = typeof RECORD_ARCHIVED;

export const CHECK_RECORD = "CHECK_RECORD";
export type CHECK_RECORD = typeof CHECK_RECORD;

export const UNCHECK_RECORD = "MARK_RECORD_AS_UNDONE";
export type UNCHECK_RECORD = typeof UNCHECK_RECORD;

export const RECORD_UNARCHIVED = "RECORD_UNARCHIVED";
export type RECORD_UNARCHIVED = typeof RECORD_UNARCHIVED;

export const RECORD_UPDATED = "RECORD_UPDATED";
export type RECORD_UPDATED = typeof RECORD_UPDATED;

export const RECORDS_UPDATED = "RECORDS_UPDATED";
export type RECORDS_UPDATED = typeof RECORDS_UPDATED;

export const RECORD_DELETED = "RECORD_DELETED";
export type RECORD_DELETED = typeof RECORD_DELETED;

export const RECORDS_REORDERED = "RECORDS_REORDERED";
export type RECORDS_REORDERED = typeof RECORDS_REORDERED;

export const CREATE_RECORD_TEMPLATE = "CREATE_RECORD_TEMPLATE";
export type CREATE_RECORD_TEMPLATE = typeof CREATE_RECORD_TEMPLATE;

export const DELETE_RECORD_TEMPLATE = "DELETE_RECORD_TEMPLATE";
export type DELETE_RECORD_TEMPLATE = typeof DELETE_RECORD_TEMPLATE;

export const RECORD_TEMPLATE_CREATED = "RECORD_TEMPLATE_CREATED";
export type RECORD_TEMPLATE_CREATED = typeof RECORD_TEMPLATE_CREATED;

export const RECORD_TEMPLATE_DELETED = "RECORD_TEMPLATE_DELETED";
export type RECORD_TEMPLATE_DELETED = typeof RECORD_TEMPLATE_DELETED;

// Checklist actions
export const ASSIGN_CHECKLIST = "ASSIGN_CHECKLIST";
export type ASSIGN_CHECKLIST = typeof ASSIGN_CHECKLIST;

export const CHECKLIST_ASSIGNED = "CHECKLIST_ASSIGNED";
export type CHECKLIST_ASSIGNED = typeof CHECKLIST_ASSIGNED;

export const UNASSIGN_CHECKLIST = "UNASSIGN_CHECKLIST";
export type UNASSIGN_CHECKLIST = typeof UNASSIGN_CHECKLIST;

export const CHECKLIST_UNASSIGNED = "CHECKLIST_UNASSIGNED";
export type CHECKLIST_UNASSIGNED = typeof CHECKLIST_UNASSIGNED;

// Tag
export const CREATE_LABEL = "CREATE_LABEL";
export type CREATE_LABEL = typeof CREATE_LABEL;

export const UPDATE_LABEL = "UPDATE_LABEL";
export type UPDATE_LABEL = typeof UPDATE_LABEL;

export const LABEL_LOADED = "LABEL_LOADED";
export type LABEL_LOADED = typeof LABEL_LOADED;

export const LABELS_LOADED = "LABELS_LOADED";
export type LABELS_LOADED = typeof LABELS_LOADED;

export const DELETE_LABEL = "DELETE_LABEL";
export type DELETE_LABEL = typeof DELETE_LABEL;

export const LABEL_CREATED = "LABEL_CREATED";
export type LABEL_CREATED = typeof LABEL_CREATED;

export const LABEL_UPDATED = "LABEL_UPDATED";
export type LABEL_UPDATED = typeof LABEL_UPDATED;

export const LABEL_DELETED = "LABEL_DELETED";
export type LABEL_DELETED = typeof LABEL_DELETED;

// RECORD FIELD
export const CREATE_RECORD_FIELD = "CREATE_RECORD_FIELD";
export type CREATE_RECORD_FIELD = typeof CREATE_RECORD_FIELD;

export const UPDATE_RECORD_FIELD = "UPDATE_RECORD_FIELD";
export type UPDATE_RECORD_FIELD = typeof UPDATE_RECORD_FIELD;

export const SET_RECORD_FIELD = "SET_RECORD_FIELD";
export type SET_RECORD_FIELD = typeof SET_RECORD_FIELD;

export const CREATE_RECORD_FIELD_VALUE = "CREATE_RECORD_FIELD_VALUE";
export type CREATE_RECORD_FIELD_VALUE = typeof CREATE_RECORD_FIELD_VALUE;

export const UPDATE_RECORD_FIELD_VALUE = "UPDATE_RECORD_FIELD_VALUE";
export type UPDATE_RECORD_FIELD_VALUE = typeof UPDATE_RECORD_FIELD_VALUE;

export const DELETE_RECORD_FIELD_VALUE = "DELETE_RECORD_FIELD_VALUE";
export type DELETE_RECORD_FIELD_VALUE = typeof DELETE_RECORD_FIELD_VALUE;

export const RECORD_FIELD_VALUE_CREATED = "RECORD_FIELD_VALUE_CREATED";
export type RECORD_FIELD_VALUE_CREATED = typeof RECORD_FIELD_VALUE_CREATED;

export const RECORD_FIELD_VALUE_UPDATED = "RECORD_FIELD_VALUE_UPDATED";
export type RECORD_FIELD_VALUE_UPDATED = typeof RECORD_FIELD_VALUE_UPDATED;

export const RECORD_FIELD_VALUE_DELETED = "RECORD_FIELD_VALUE_DELETED";
export type RECORD_FIELD_VALUE_DELETED = typeof RECORD_FIELD_VALUE_DELETED;

export const MOVE_RECORD_FIELD = "MOVE_RECORD_FIELD";
export type MOVE_RECORD_FIELD = typeof MOVE_RECORD_FIELD;

export const DELETE_RECORD_FIELD = "DELETE_RECORD_FIELD";
export type DELETE_RECORD_FIELD = typeof DELETE_RECORD_FIELD;

export const RECORD_FIELD_CREATED = "RECORD_FIELD_CREATED";
export type RECORD_FIELD_CREATED = typeof RECORD_FIELD_CREATED;

export const RECORD_FIELD_UPDATED = "RECORD_FIELD_UPDATED";
export type RECORD_FIELD_UPDATED = typeof RECORD_FIELD_UPDATED;

export const RECORD_FIELD_MOVED = "RECORD_FIELD_MOVED";
export type RECORD_FIELD_MOVED = typeof RECORD_FIELD_MOVED;

export const RECORD_FIELD_DELETED = "RECORD_FIELD_DELETED";
export type RECORD_FIELD_DELETED = typeof RECORD_FIELD_DELETED;
