import { put, takeEvery, all, select } from "redux-saga/effects";
import { dispatch } from "..";
import * as Actions from "../actions/types";
import * as AppActions from "../actions/app";
import { RecordSchema } from "../schemas";
import * as BoardActions from "../actions/board";
import * as SpaceActions from "../actions/space";
import * as ThreadActions from "../actions/thread";
import Client, { io } from "@colab/client";

function* normalize(payload: io.Record | io.Record[]): Iterable<any> {
    let [record, related] = RecordSchema.normalize(payload);
    yield put(AppActions.relatedLoaded(related as any) as any);
    return record;
}

function* updated(payload: io.Record | io.Record[]): Iterable<any> {
    let record = yield* normalize(payload);
    if (Array.isArray(record)) {
        yield put(BoardActions.recordsUpdated(record as any));
    } else {
        yield put(BoardActions.recordUpdated(record as any));
    }
}

function* normalizeLoad(payload: io.Record | io.Record[]): Iterable<any> {
    let records = yield* normalize(payload);
    if (!Array.isArray(records)) {
        records = [records];
    }
    yield* load(records);
}

function* load(records: any): Iterable<any> {
    let actions = records
        .sort((a: any, b: any) => a.index - b.index)
        .map((record: any) => {
            return put(BoardActions.recordLoaded(record));
        });
    yield all(actions);
}

function* fetch({
    payload,
    resolve,
}: BoardActions.FetchRecordsAction): Iterable<any> {
    try {
        const data = (yield Client.fetchRecords(payload)) as any;
        resolve.success(data);
    } catch (e) {
        resolve.error(e);
    }
}

function* fetchSpaceRecords({
    payload,
    resolve,
}: BoardActions.LoadRecordsAction): Iterable<any> {
    try {
        const data = (yield yield put(
            BoardActions.fetchRecords(payload)
        )) as any as io.Record[];

        yield* normalizeLoad(data);
        yield put(AppActions.dataLoaded(payload.space_id, "records", data));
        resolve.success(data);
    } catch (e) {
        resolve.error(e);
    }
}

function* loadRecord({
    payload,
    resolve,
}: BoardActions.LoadRecordAction): Iterable<any> {
    try {
        const data = (yield Client.getRecord(payload)) as any as io.Record;
        yield* normalizeLoad(data);
        resolve.success(data);
    } catch (e) {
        resolve.error(e);
    }
}

function* loadArchivedRecords({
    payload,
    resolve,
}: BoardActions.LoadArchivedRecordsAction): Iterable<any> {
    try {
        const data = (yield Client.fetchArchivedRecords(payload)) as any;
        yield* normalizeLoad(data);
        yield put(
            AppActions.dataLoaded(
                payload.space_id,
                "records.archived",
                data
            )
        );
        resolve.success(data);
    } catch (e) {
        resolve.error(e);
    }
}
function* loadBoardRecords({
    payload,
}: SpaceActions.SpaceLoadedAction): Iterable<any> {
    if(payload.type === "board"){
        yield put(BoardActions.loadBoardRecords({ space_id: payload.id! }));
    }
}

function* create({
    payload,
    resolve,
}: BoardActions.CreateRecordAction): Iterable<any> {
    try {
        const data = (yield Client.createRecord(payload)) as any;
        let record = yield* normalize(data);
        yield put(BoardActions.recordCreated(record));

        resolve.success(data);
    } catch (e) {
        resolve.error(e);
    }
}

function* update({
    payload,
    resolve,
}: BoardActions.UpdateRecordAction): Iterable<any> {
    try {
        const data = (yield Client.updateRecord(payload)) as any;
        let [record, related] = RecordSchema.normalizeOne(data as any) as any;
        yield put(AppActions.relatedLoaded(related));
        yield put(BoardActions.recordUpdated(record));
        resolve.success(data);
    } catch (e) {
        resolve.error(e);
    }
}

function* move({
    payload,
    resolve,
}: BoardActions.MoveRecordAction): Iterable<any> {
    try {
        if (payload.index == 0 || payload.index) {
            yield put(
                BoardActions.recordMoved({
                    id: payload.record_id,
                    index: payload.index,
                    collection_id: payload.collection_id,
                })
            );
        }
        const data = (yield Client.moveRecord(payload)) as any;
        let record = yield* normalize(data);
        yield put(BoardActions.recordUpdated({ ...record, archived_at: null }));
        resolve.success(data);
    } catch (e) {
        resolve.error(e);
    }
}

function* related({ payload }: AppActions.RelatedLoadedAction): Iterable<any> {
    let records = RecordSchema.getCollection(payload as any);

    if (records.length > 0) {
        yield* load(records);
    }
}

function* trash({
    payload,
    resolve,
}: BoardActions.DeleteRecordAction): Iterable<any> {
    try {
        const data = (yield Client.deleteRecord(payload)) as any;
        resolve.success(data);
        yield put(BoardActions.recordDeleted(payload.record_id));
    } catch (e) {
        resolve.error(e);
    }
}

function* archive({
    payload,
    resolve,
}: BoardActions.ArchiveRecordAction): Iterable<any> {
    try {
        const data = (yield Client.archiveRecord(payload)) as any;
        let record = yield* normalize(data);
        yield put(BoardActions.recordArchived(record));
        resolve.success(data);
    } catch (e) {
        resolve.error(e);
    }
}

function* done({
    payload,
    resolve,
}: BoardActions.CheckRecordAction): Iterable<any> {
    try {
        const data = (yield Client.checkRecord(payload)) as any;
        yield updated(data);
        resolve.success(data);
    } catch (e) {
        resolve.error(e);
    }
}

function* undone({
    payload,
    resolve,
}: BoardActions.CheckRecordAction): Iterable<any> {
    try {
        const data = (yield Client.uncheckRecord(payload)) as any;
        yield updated(data);
        resolve.success(data);
    } catch (e) {
        resolve.error(e);
    }
}

function* store({ payload }: any) {
    yield* load(payload);
}

function* subscribe({ payload }: SpaceActions.SpaceConnectedAction): Iterable<any> {
    const { channel } = payload;
    const store = (yield select()) as any;
    const space = store.spaces.getSpace(payload.space_id);

    if(space && space.is_board) {
        channel.on("record.created", (payload: io.Record) => {
            let [record, related] = RecordSchema.normalizeOne(payload);
            dispatch(AppActions.relatedLoaded(related));
            dispatch(BoardActions.recordCreated(record as any));
        });

        channel.on("record.updated", (payload: io.Record) => {
            let [record, related] = RecordSchema.normalizeOne(payload);
            dispatch(AppActions.relatedLoaded(related));
            dispatch(BoardActions.recordUpdated(record as any));
        });

        channel.on("record.checked", (payload: io.Record) => {
            dispatch(BoardActions.recordUpdated(payload as any));
        });

        channel.on("record.unchecked", (payload: io.Record) => {
            dispatch(BoardActions.recordUpdated(payload as any));
        });

        channel.on("record.done", (payload: io.Record) => {
            let [record, related] = RecordSchema.normalizeOne(payload);
            dispatch(AppActions.relatedLoaded(related));
            dispatch(BoardActions.recordUpdated(record as any));
        });

        channel.on("record.undone", (payload: io.Record) => {
            let [record, related] = RecordSchema.normalizeOne(payload);
            dispatch(AppActions.relatedLoaded(related));
            dispatch(BoardActions.recordUpdated(record as any));
        });

        channel.on("record.archived", (payload: io.Record) => {
            let [record, related] = RecordSchema.normalizeOne(payload);
            dispatch(AppActions.relatedLoaded(related));
            dispatch(BoardActions.recordArchived(record as any));
        });

        channel.on("record.unarchived", (payload: io.Record) => {
            let [record, related] = RecordSchema.normalizeOne(payload);
            dispatch(AppActions.relatedLoaded(related));
            dispatch(BoardActions.recordUnarchived(record as any));
        });

        channel.on("record.deleted", (payload: any) => {
            dispatch(BoardActions.recordDeleted(payload.id));
            dispatch(
                ThreadActions.threadDeleted({
                    id: payload.thread_id,
                    space_id: payload.space_id,
                })
            );
        });

        channel.on("records.reordered", ({ records }: { records: io.Record[] }) => {
            dispatch(BoardActions.recordsReordered(records));
        });

        channel.on("record.moved", (payload: io.Record) => {
            dispatch(BoardActions.recordMoved(payload));
        });
    }
}

export const tasks = [
    { effect: takeEvery, type: Actions.SPACE_CONNECTED, handler: subscribe },

    { effect: takeEvery, type: Actions.RELATED_LOADED, handler: related },

    { effect: takeEvery, type: Actions.MOVE_RECORD, handler: move },

    { effect: takeEvery, type: Actions.CREATE_RECORD, handler: create },

    { effect: takeEvery, type: Actions.LOAD_RECORDS, handler: fetchSpaceRecords},

    {
        effect: takeEvery,
        type: Actions.SPACE_LOADED,
        handler: loadBoardRecords,
    },

    {
        effect: takeEvery,
        type: Actions.LOAD_ARCHIVED_RECORDS,
        handler: loadArchivedRecords,
    },
    { effect: takeEvery, type: Actions.STORE_RECORDS, handler: store },

    { effect: takeEvery, type: Actions.STORE_RECORD, handler: store },

    { effect: takeEvery, type: Actions.DELETE_RECORD, handler: trash },

    { effect: takeEvery, type: Actions.CHECK_RECORD, handler: done },

    { effect: takeEvery, type: Actions.LOAD_RECORD, handler: loadRecord },

    { effect: takeEvery, type: Actions.UNCHECK_RECORD, handler: undone },

    { effect: takeEvery, type: Actions.ARCHIVE_RECORD, handler: archive },

    { effect: takeEvery, type: Actions.UPDATE_RECORD, handler: update },

    { effect: takeEvery, type: Actions.FETCH_RECORDS, handler: fetch },
];
