import React, { useEffect, useId, useState } from "react";
import Calendar, { ISelectEvent } from "./Calendar";
import UIEvent from "../event";
import { Base } from "../Button";

export interface IChangeEvent {
    event: React.MouseEvent;
    target: { value: string };
}

export interface IPicker {
    value?: string | null;
    time?: boolean;
    disabled?: boolean;
    clear?: string | React.ReactNode;
    confirm?: string | React.ReactNode;
    onChange: (e: UIEvent) => void;
    onClear?: (e: React.MouseEvent) => void;
}

function getTime(date: Date): string {
    let hr = date.getHours().toString();
    let min = date.getMinutes().toString();
    if (hr.length === 1) {
        hr = "0" + hr;
    }
    if (min.length === 1) {
        min = "0" + min;
    }
    return `${hr}:${min}`;
}

export default function Picker({
    value,
    onChange,
    onClear,
    confirm = "Okay",
    clear = "Clear",
    disabled = false,
    ...props
}: IPicker) {
    const [time, setTime] = useState("");
    const [date, setDate] = useState<null | Date>(null);
    const [dvalue, setDvalue] = useState<null | string>(null);
    const [datetime, setDatetime] = useState(value);
    const timeid = useId();

    useEffect(() => {
        if (value) {
            let date = new Date(value);
            setDate(date);
            if (props.time) {
                setTime(getTime(date));
            } else {
                setTime("00:00");
            }
            setDvalue(date.toISOString());
        } else {
            setTime("");
            setDate(null);
            setDvalue(null);
        }
    }, [value]);

    useEffect(() => {
        if (date && Boolean(time)) {
            let [hh, mm] = time.split(":");
            const hr = parseInt(hh);
            const min = parseInt(mm);
            const dt = new Date(date.toISOString());
            dt.setHours(hr);
            dt.setMinutes(min);
            setDatetime(dt.toISOString());
        } else {
            setDatetime(null);
        }
    }, [date, time]);

    function handleSetTime(e: any) {
        setTime(e.target.value);
        if (!Boolean(date)) {
            setDate(new Date());
        }
    }

    function handleSetDate({ target: { value } }: ISelectEvent) {
        setDate(value);
        if (!Boolean(time)) {
            if (props.time) {
                setTime("23:59");
            } else {
                setTime("00:00");
            }
        }
    }

    function handleChangeValue(event: React.MouseEvent) {
        if (datetime) {
            onChange(UIEvent.create({ value: datetime }, event));
        }
    }

    return (
        <div className="flex flex-col rounded-md">
            <div className="flex flex-col p-4">
                <Calendar selected={date} onSelect={handleSetDate} />
                {props.time && (
                    <div className="relative">
                        <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                            <svg className="w-4 h-4 text-dark-500 dark:text-dark-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clip-rule="evenodd"/>
                            </svg>
                        </div>
                        <input 
                            type="time" 
                            id={timeid}
                            value={time}
                            onChange={handleSetTime}
                            className="bg-dark-50 border leading-none border-dark-300 text-dark-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-dark-700 dark:border-dark-600 dark:placeholder-dark-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"  required />
                    </div>
                )}
            </div>
            <div className="flex flex-row items-center p-2">
                {onClear ? (
                    <>
                        <Base
                            onClick={onClear}
                            disabled={!Boolean(dvalue) || disabled}
                            disabledClass="bg-gray-50 dark:bg-transparent text-gray-400 dark:text-dark-300"
                            activeClass=" text-gray dark:text-dark-200 bg-gray-100 dark:bg-dark-700"
                            className="flex-grow mx-2 rounded-md py-2 font-semibold text-center">
                            {clear}
                        </Base>
                        <Base
                            disabled={
                                !Boolean(datetime) ||
                                dvalue == datetime ||
                                disabled
                            }
                            onClick={handleChangeValue}
                            disabledClass="bg-gray-50 text-gray-400 dark:bg-dark-700 dark:text-dark-400"
                            activeClass="text-white bg-primary-500"
                            className="flex-grow mx-2 rounded-md py-2 font-semibold text-center">
                            {confirm}
                        </Base>
                    </>
                ) : (
                    <Base
                        disabled={
                            !Boolean(datetime) || dvalue == datetime || disabled
                        }
                        onClick={handleChangeValue}
                        disabledClass="bg-gray-50 text-gray-400"
                        activeClass="text-white bg-primary-500"
                        className="flex-grow mx-2 rounded-md py-2 font-semibold text-center">
                        {confirm}
                    </Base>
                )}
            </div>
        </div>
    );
}
