import { takeEvery, put, all, select } from "redux-saga/effects";
import client, { io } from "@colab/client";
import { dispatch, State } from "..";
import * as Actions from "../actions/types";
import * as BoardActions from "../actions/board";
import * as SpaceActions from "../actions/space";
import * as AppActions from "../actions/app";

function* createRecordTemplate({
    payload,
    resolve,
}: BoardActions.CreateRecordTemplateAction): Iterable<any> {
    try {
        const data = (yield client.createRecordTemplate(payload)) as any;
        yield put(BoardActions.recordTemplateCreated(data));
        resolve.success(data);
    } catch (e) {
        resolve.error(e);
    }
}

function* deleteRecordTemplate({
    payload,
    resolve,
}: BoardActions.DeleteRecordTemplateAction): Iterable<any> {
    try {
        const data = (yield client.deleteRecordTemplate(payload)) as any;
        yield put(
            BoardActions.recordTemplateDeleted({
                id: payload.template_id,
                ...payload,
            })
        );
        resolve.success(data);
    } catch (e) {
        resolve.error(e);
    }
}

function* spaceLoaded({
    payload,
}: SpaceActions.SpaceLoadedAction): Iterable<any> {
    //yield put(BoardActions.loadSpaceBoards(payload.id!));
}

function* subscribeSpace({
    payload: { channel },
}: SpaceActions.SpaceConnectedAction): Iterable<any> {

}

function* subscribe({
    payload: { channel },
}: SpaceActions.SpaceConnectedAction): Iterable<any> {
    channel.on("record_template.created", (payload: io.RecordTemplate) => {
        dispatch(BoardActions.recordTemplateCreated(payload));
    });
    channel.on("record_template.deleted", (payload: io.RecordTemplate) => {
        dispatch(BoardActions.recordTemplateDeleted(payload));
    });
}

function* unsubscribe({
    payload,
}: BoardActions.BoardPurgedAction): Iterable<any> {
    const topic = `space:${payload.id}`;
    let channel = client.topic(topic);
    if (channel) {
        channel.unsubscribe();
    }
}

function* spacePurged({
    payload,
}: SpaceActions.SpacePurgedAction): Iterable<any> {
    const { boards } = (yield select()) as any as State;
    const bids = boards.spaces.get(payload.space_id);
    if (bids) {
        for (let id of bids.toArray()) {
            yield put(BoardActions.purgeBoard({ id }));
        }
    }
}

export const tasks = [
    {effect: takeEvery, type: Actions.SPACE_CONNECTED, handler: subscribeSpace},
    {effect: takeEvery, type: Actions.SPACE_LOADED, handler: spaceLoaded },
    {
        effect: takeEvery,
        type: Actions.SPACES_LOADED,
        handler: spaceLoaded,
    },
    {
        effect: takeEvery,
        type: Actions.CREATE_RECORD_TEMPLATE,
        handler: createRecordTemplate,
    },
    {
        effect: takeEvery,
        type: Actions.DELETE_RECORD_TEMPLATE,
        handler: deleteRecordTemplate,
    }
];
